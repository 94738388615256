import { Component, Inject, EventEmitter, Output, Input, ChangeDetectorRef } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { application } from '../../globals';
import { evision5 } from '../../globals';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { trigger, state, transition, animate, style } from '@angular/animations';
import { UriBuilderService } from '../_services/uribuilder.service';
import { encodeUriFragment } from '@angular/router/src/url_tree';

@Component({
  selector: 'app-webpresenter',
  templateUrl: './webpresenter.component.html',
  styleUrls: ['./webpresenter.component.css']
  //animations: [
  //  trigger('', [
  //    state('first', style({ height: '0', opacity: '0' })),
  //    state('second', style({ height: '*', opacity: '1' })),
  //    transition('first => second', animate('')),
  //    transition('second => first', animate(''))
  //  ])
  //]
})

export class WebPresenterComponent
{
  private application: IGlobal = application;
  private evision5: IGlobalEvision5 = evision5;

  private deviceID: string = null;
  private fullscreen: boolean = false;
  private muted: boolean = true;

  private isInitialUrlRequest: boolean = true;

  private interval: number = 5000;
  private intervalID: number = null;

  private settings: IMediaSettingsWebPresenterCollection = null;
  private device: IMediaDeviceForWebPresenter = null;

  // STATES: fadein, visible, hidden
  private mediaFrames: IContentFrame[] = [];
  private active: number = null;
  private urls: SafeResourceUrl[] = [];

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private changeDetector: ChangeDetectorRef,
    private uriBuilderService: UriBuilderService)
  {
    this.baseUrl = baseUrl;
    this.http = http;

    this.mediaFrames.push({ url: null, isActive: false, state: 'hidden', secondsPerPage: null });
    this.mediaFrames.push({ url: null, isActive: false, state: 'hidden', secondsPerPage: null });

    this.urls[0] = null;
    this.urls[1] = null;

    this.getSettings();
  };

  private ngOnInit()
  {
    this.deviceID = this.route.snapshot.paramMap.get('deviceID');

    // Get parameters
    this.route.queryParams.subscribe(params =>
    {
      this.fullscreen = params['fullscreen'] === 'true';
    });

    // Trigger updating refresh-stamp on initial-load. Then get first url.
    this.http
      .post<IReturnState>(evision5.buildApi(this.baseUrl, 'mediadevice/' + (this.deviceID != null ? this.deviceID : '00000000-0000-0000-0000-000000000000') + '/reloadmedia'), null)
      .subscribe(result =>
      {

        this.getData();


      }, error => console.error(error));
  };

  private getData()
  {
    this.intervalID = window.setInterval(() =>
    {
      // Check the current interval
      var ms = 5000;
      if (this.settings !== null && this.settings.refreshSettingsMsIntervalValue >= 0)
      {
        ms = this.settings.refreshSettingsMsIntervalValue;
      }

      if (ms !== this.interval)
      {
        // Interval has changed by settings. Therefore cancel current
        // interval and restart via calling getData().
        window.clearInterval(this.intervalID);
        this.intervalID = null;
        this.interval = ms;
        this.getData();
        return;
      }

      this.getSettings();
      this.getDevice();

    }, this.interval);
  };

  /**
   * Sets the mute-option. Requires device and settings.
   **/
  private setMute()
  {
    if (this.device !== null && this.settings !== null)
    {
      var dvc = this.device;
      var set = this.settings;

      var m = dvc.silentVideoPlayback === -1
        ? set.silentVideoPlaybackValue
        : dvc.silentVideoPlayback === 1
          ? true
          : false;
      this.muted = m;
    }

    if (this.isInitialUrlRequest === true)
    {
      this.getUrl();
      this.isInitialUrlRequest = false;
    }
  };

  private getSettings()
  {
    this.http
      .get<IReturnState>(evision5.buildApi(this.baseUrl, 'mediasettings/webpresenter'))
      .subscribe(result =>
      {
        this.settings = result.data as IMediaSettingsWebPresenterCollection;
        this.setMute();

      }, error => console.error(error));
  };

  private getDevice()
  {
    var url = this.deviceID != null
      ? 'mediadevice/id/' + this.deviceID
      : 'mediadevice/current';

    this.http
      .get<IReturnState>(evision5.buildApi(this.baseUrl, url))
      .subscribe(result =>
      {
        this.device = result.data as IMediaDeviceForWebPresenter;
        this.setMute();

      }, error => console.error(error));
  };

  private getUrl()
  {
    var header = {
      headers: new HttpHeaders({
        'fullscreen': this.fullscreen.toString(),
        'accept': 'application/json'
      })
    };

    var url = this.deviceID != null
      ? 'mediaactivity/content/mediadevice/' + this.deviceID
      : 'mediaactivity/content';

    this.http
      .get<IMediaActivityContent>(evision5.buildApi(this.baseUrl, url), header)
      .subscribe(result =>
      {
        this.detectChange(result);
      }, error => console.error(error));
  };

  private detectChange(_content: IMediaActivityContent)
  {
    var activeIndex = this.active === null ? 0 : this.active,
      newActiveIndex = activeIndex === 0 ? 1 : 0;

    _content.value = this.modifyUrl(_content.value);
    if (this.mediaFrames[activeIndex].url !== _content.value || this.mediaFrames[activeIndex].secondsPerPage !== _content.secondsPerPage)
    {
      this.mediaFrames[newActiveIndex].url = _content.value;
      this.mediaFrames[newActiveIndex].secondsPerPage = _content.secondsPerPage;
      this.toggle();
    }
    else
    {
      // Get url and check
      setTimeout(() =>
      {
        this.getUrl();

      }, 1000);
    }
  };

  private toggle()
  {
    var activeIndex = this.active === null ? 0 : this.active,
      newActiveIndex = activeIndex === 0 ? 1 : 0;

    var currentItem = this.mediaFrames[activeIndex],
      newActiveItem = this.mediaFrames[newActiveIndex];

    // Set url for item
    var tmp = newActiveItem.url;
    this.urls[newActiveIndex] = tmp;

    // Set 'fadein'
    newActiveItem.isActive = true;
    newActiveItem.state = 'fadein';

    // Set timeout to change to 'visible'
    setTimeout(() =>
    {
      // Change active-index
      this.active = newActiveIndex;

      // Reset state of previous visible item.
      currentItem.isActive = false;
      currentItem.state = 'hidden';

      // Change to 'visible'
      newActiveItem.state = 'visible';

      // Get url and check
      setTimeout(() =>
      {
        this.getUrl();

      }, 1000);

    }, this.settings.crossFadingDurationMsValue);
  };

  private modifyUrl(_url: string): string
  {
    var url = _url;

    if (this.settings !== null)
    {
      var isYouTube = false;
      var isVimeo = false;
      var isTwitch = false;
      for (var i = 0; i < this.settings.youTubeHostsValue.length; i++)
      {
        if (url.indexOf(this.settings.youTubeHostsValue[i]) >= 0)
        {
          isYouTube = true;
          break;
        }

        if (url.indexOf(this.settings.vimeoHostsValue[i]) >= 0)
        {
          isVimeo = true;
          break;
        }

        if (url.indexOf(this.settings.twitchHostsValue[i]) >= 0)
        {
          isTwitch = true;
          break;
        }
      }

      if (isYouTube)
      {
        var uriBuilder = this.uriBuilderService.Get();
        url = uriBuilder
          .Parse(url)
          .ClearSegments()
          .AddSegment('embed')
          .AddSegment(uriBuilder.QueryStrings['v'])
          .AddQueryString('playlist', uriBuilder.QueryStrings['v'])
          .AddQueryString('autoplay', '1')
          .AddQueryString('controls', '0')
          .AddQueryString('loop', '1')
          .AddQueryString('showinfo', '0')
          .AddQueryString('mute', (this.muted ? '1' : '0'))
          .RemoveQueryString('v')
          .RemoveQueryString('ts')
          .RemoveQueryString('fullscreen')
          .Build();
      }

      if (isVimeo)
      {
        var uriBuilder = this.uriBuilderService.Get().Parse(url);
        var vimeoId = uriBuilder.Segments[0].Value;

        url = uriBuilder
          .ClearSegments()
          .UseHost('player.vimeo.com')
          .AddSegment('video')
          .AddSegment(vimeoId)
          .AddQueryString('autoplay', '1')
          .AddQueryString('loop', '1')
          .AddQueryString('autopause', '0')
          .AddQueryString('muted', (this.muted ? 'true' : 'false'))
          .RemoveQueryString('v')
          .RemoveQueryString('ts')
          .RemoveQueryString('fullscreen')
          .Build();
      }

      if (isTwitch)
      {
        var uriBuilder = this.uriBuilderService.Get().Parse(url);

        var isTwitchVideo = uriBuilder.Segments.length > 0 && uriBuilder.Segments[0].Value === 'videos';
        var twitchVideoID = uriBuilder.Segments.length > 1
          ? uriBuilder.Segments[1].Value
          : '';

        url = uriBuilder
          .ClearSegments()
          .UseHost('player.twitch.tv')
          .AddQueryString(isTwitchVideo ? 'video' : 'channel', twitchVideoID)
          .AddQueryString('muted', (this.muted ? '1' : '0'))
          .AddQueryString('autoplay', 'true')
          .AddQueryString('parent', encodeURIComponent('www.evision5.cloud'))
          .RemoveQueryString('ts')
          .RemoveQueryString('fullscreen')
          .Build();
      }
    }

    return url;
  };
}
